import { Box } from "@mui/material";

import { logoSelector } from "portalConfig/selectors";
import { useSelector } from "react-redux";
import HeaderRightSection from "./HeaderRightSection";

const Header = () => {
  const logoUrl = useSelector(logoSelector);
  return (
    <Box
      sx={{
        display: "flex",
        p: "20px",

        alignItems: "center",
        justifyContent: "space-between",
      }}
      data-cy="app-header"
    >
      <Box
        component="img"
        sx={{
          maxWidth: "120px",
        }}
        alt="Image"
        src={logoUrl}
      />
      <Box>
        <HeaderRightSection />
      </Box>
    </Box>
  );
};

export default Header;
