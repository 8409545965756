import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useSearchParams } from "react-router-dom";
import DOMPurify from "dompurify";
import parse from "html-react-parser";

import DashboardLinkHeader from "components/common/DashboardLinkHeader/DashboardLinkHeader";
import ErrorComponent from "components/common/ErrorComponent/ErrorComponent";
import FooterNavigation from "components/common/FooterNavigation/FooterNavigation";
import HorizontalStepper from "components/common/HorizontalStepper/HorizontalStepper";
import Stepper from "components/common/Stepper/Stepper";
import { Support, SHOW_SUPPORT_ON_NEW_QUOTES_PAGE } from "components/Support";

import {
  FAIRFAX,
  IS_BROKER_PORTAL,
  IS_HOSTED_INSURANCE,
  PORTAL_NAME,
} from "config";
import { stepperSteps } from "constants/stepperSteps";
import { ClientProduct } from "customTypes/ClientProducts";
import {
  defaultProductsSelector,
  xApiKeySelector,
  footerNoteSelector,
} from "portalConfig/selectors";
import {
  saveClientSentInfo,
  setErrorAndShowExitBtn,
  updateStartPageSelection,
} from "store/features/clientSlice";
import { setCurentStep } from "store/features/stepperSlice";
import {
  setClientSIALatestConfig,
  setSIAApplicationId,
} from "store/features/surveyJsSlice";
import { authSelector, customStylesSelector } from "store/selectors/selectors";
import { RootState } from "store/store";
import { PaymentProviders, sendMessageToClient } from "utils/utils";
import { getFromLocalStorage, LSKeys } from "utils/ls.utils";

const NewQuote = () => {
  const customStyles = useSelector(customStylesSelector);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { portalAccount } = useSelector(authSelector);
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [applicationId, setApplicationId] = useState(
    searchParams.get("applicationId")
  );
  const isPaymentScreen = location?.pathname?.split("/")[2] === "payment";

  const isTab = useMediaQuery("(max-width:900px)");
  const isMobile = useMediaQuery("(max-width:480px)");
  const { currentStep, currentStepLabel } = useSelector(
    (state: RootState) => state.stepper
  );
  const defaultProducts = useSelector(defaultProductsSelector);
  const { availableProducts } = useSelector((state: RootState) => state.client);
  const {
    errorAndShowExitBtn,
    simulateErrorConfig,
    clientUrl,
    clientFeatures,
  } = useSelector((state: RootState) => state.client);
  const xApiKey = useSelector(xApiKeySelector);

  const footerNote = useSelector(footerNoteSelector);
  const sanitizedHtml = DOMPurify.sanitize(footerNote);
  // Flag to show the footer note. For example, Fairfax.
  // More portals can be added in the below array if needed.
  const SHOW_FOOTER_NOTE = [FAIRFAX].includes(PORTAL_NAME) && footerNote;

  const fetchApplication = async () => {
    try {
      setLoading(true);
      let applicantID = localStorage.getItem("currentApplicantID");
      if (!applicantID) {
        applicantID = `applicant_${new Date().getTime()}`;
        localStorage.setItem("currentApplicantID", applicantID);
      }
      dispatch(
        saveClientSentInfo({
          "X-API-KEY": xApiKey,
          applicant_id: applicantID,
          product_selection: defaultProducts,
        })
      );
      dispatch(
        setClientSIALatestConfig({
          touched: true,
        })
      );
      dispatch(setSIAApplicationId(applicationId));
      dispatch(setCurentStep(1));
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (applicationId) {
      fetchApplication();
    }
  }, [applicationId]);

  useEffect(() => {
    if (portalAccount || getFromLocalStorage(LSKeys.ACCESS_TOKEN)) {
      let applicantID = localStorage.getItem("currentApplicantID");
      if (!applicantID) {
        applicantID = `applicant_${new Date().getTime()}`;
        localStorage.setItem("currentApplicantID", applicantID);
      }
      // This is temporary need to change after this release
      dispatch(
        saveClientSentInfo({
          "X-API-KEY": xApiKey,
          applicant_id: applicantID,
          product_selection: defaultProducts,
        })
      );
      if (applicationId || PORTAL_NAME === FAIRFAX) {
        dispatch(
          updateStartPageSelection(
            availableProducts?.filter((prod: ClientProduct) =>
              defaultProducts.includes(prod?.product?.product_identifier)
            )
          )
        );
      }
    }
  }, [portalAccount]);

  useEffect(() => {
    if (simulateErrorConfig?.step) {
      if (simulateErrorConfig.step === currentStepLabel) {
        dispatch(setErrorAndShowExitBtn(true));
        sendMessageToClient(
          {
            type: "FAILURE",
            details: {
              section: currentStepLabel,
            },
          },
          clientUrl
        );
      }
    }
  }, [currentStep, simulateErrorConfig?.step]);

  return (
    <Grid
      container
      sx={{
        backgroundColor: customStyles.muiTheme.palette.background.default,
      }}
    >
      <Grid
        item
        md={3}
        className="customStepperClass"
        sx={{
          display: { xs: "none", md: "flex" },
          flexDirection: "column",
          justifyContent: "center",
          height: "100vh",
          backgroundColor: customStyles.stepper.backgroundColor,
        }}
      >
        <Stepper />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={9}
        lg={9}
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          overflowY: "hidden",
        }}
      >
        {IS_BROKER_PORTAL && <DashboardLinkHeader />}
        {IS_HOSTED_INSURANCE && isTab && <HorizontalStepper />}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            flexGrow: 1,
            boxSizing: "border-box",
            overflowY: "auto",
            pt: isMobile ? "20px" : "40px",
            pb:
              isMobile &&
                isPaymentScreen &&
                clientFeatures?.payment_provider === PaymentProviders.STRIPE
                ? "80px"
                : "",
          }}
        >
          {errorAndShowExitBtn ? (
            <ErrorComponent />
          ) : isMobile ? (
            <Outlet />
          ) : (
            <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
              <Outlet />
            </Box>
          )}
          {!isMobile && <FooterNavigation />}
        </Box>
        {isMobile && <FooterNavigation />}

        {SHOW_FOOTER_NOTE && (
          <Box
            sx={{
              mt: isMobile ? "0px" : "10px",
              p: "10px",
              background: customStyles?.stepper?.backgroundColor,
              // Restricting the height of the footer note to 100px and adding scroll on mobile
              ...(isMobile
                ? {
                    height: "100px",
                    overflowY: "scroll",
                  }
                : {}),
            }}
          >
            <Typography fontSize={12} color={"white"}>
              {parse(sanitizedHtml)}
            </Typography>
          </Box>
        )}

        {/* Show support option on the new quotes page if the flag is enabled for the portal */}
        {SHOW_SUPPORT_ON_NEW_QUOTES_PAGE && <Support />}
      </Grid>
    </Grid>
  );
};

export default NewQuote;
