import { IAgencyDetails } from "modules/Accounts/types";
import { IUpdateUserDetails } from "modules/Profile/types";
import api from "utils/api.utils";
import { LSKeys, getFromLocalStorage } from "utils/ls.utils";

export const updateAccountAPI = async (
  account: IAgencyDetails,
  accountId: string
): Promise<any> => {
  try {
    const response = await api.put(`accounts/${accountId}`, account);
    return response;
  } catch (err) {
    throw err;
  }
};

export const fetchAccountDetails = async (accountId: string) => {
  try {
    const response = await api.get(`accounts/${accountId}`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const fetchUserProfileDetails = {
  queryKey: ["get-user-details", getFromLocalStorage(LSKeys.ACCESS_TOKEN)],
  queryFn: async () => {
    try {
      const response = await api.get("users/profile");
      return response;
    } catch (e) {
      throw e;
    }
  },
};

export const updateUserProfileDetails = async (
  userDetails: IUpdateUserDetails
): Promise<any> => {
  try {
    const response = await api.put("users/profile", userDetails);
    return response;
  } catch (err) {
    throw err;
  }
};
