import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { IS_BROKER_PORTAL } from "config";
import { stepperSteps } from "constants/stepperSteps";

const allSteps = stepperSteps()

interface StepperState {
  steps: Step[];
  subSteps: Step[];
  currentStep: number;
  currentStepLabel: string;
  maxSteps: number;
  currentSubStep: number;
  maxSubSteps: number;
  loading: boolean;
  showPaymentScreen: boolean;
}

const initialState: StepperState = {
  steps: allSteps,
  subSteps: [],
  currentStepLabel: allSteps[0].label ?? "",
  currentStep: 0,
  maxSteps: IS_BROKER_PORTAL ? 4 : 5,
  currentSubStep: 0,
  maxSubSteps: -1,
  loading: false,
  showPaymentScreen: false,
};

const stepperSlice = createSlice({
  name: "stepper",
  initialState,
  reducers: {
    setSteps: (state, action: PayloadAction<Step[]>) => {
      state.steps = action.payload;
    },
    setSubSteps: (state, action: PayloadAction<Step[]>) => {
      state.subSteps = action.payload;
      state.maxSubSteps = state.subSteps.length;
    },
    nextStep: (state) => {
      if (state.currentStep >= state.maxSteps - 1) {
        return;
      }
      state.currentStep += 1;
      state.currentStepLabel = state.steps?.[state.currentStep]?.label ?? "";
      if (state.currentStep === 1) {
        state.currentSubStep = 0;
      }
      if (state.currentSubStep === state.maxSubSteps - 1)
        state.currentSubStep += 1;
    },
    prevStep: (state) => {
      if (state.currentStep === 0) {
        return;
      }
      state.currentStep -= 1;
      state.currentStepLabel = state.steps?.[state.currentStep]?.label ?? "";
    },
    nextSubStep: (state) => {
      state.currentSubStep += 1;
    },
    prevSubStep: (state) => {
      state.currentSubStep -= 1;
    },
    setCurrentSubStep: (state, action: PayloadAction<number>) => {
      state.currentSubStep = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setCurentStep: (state, action: PayloadAction<number>) => {
      state.currentStep = action.payload;
      state.currentStepLabel = state.steps?.[action.payload]?.label ?? "";
    },
    resetStepper: (state, action: PayloadAction<number>) => ({
      ...initialState,
      currentStep: action.payload,
      currentStepLabel: state.steps?.[action.payload]?.label ?? ""
    }),
    setShowPaymentScreen: (state, action: PayloadAction<boolean>) => {
      state.showPaymentScreen = action.payload;
    },
  },
});

export const {
  nextStep,
  prevStep,
  setSteps,
  setSubSteps,
  prevSubStep,
  nextSubStep,
  setCurrentSubStep,
  setLoading,
  setCurentStep,
  resetStepper,
  setShowPaymentScreen,
} = stepperSlice.actions;
export default stepperSlice.reducer;
