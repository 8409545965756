import { Badge, Box, Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import CustomerDetailsPopup from "components/common/Header/CustomerDetailsPopup";
import AnimatedButton from "components/custom/AnimatedButton";
import { CORX, FAIRFAX, PORTAL_NAME } from "config";
import { initialCurrentStepSelector } from "portalConfig/selectors";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { updateStartPageSelection } from "store/features/clientSlice";
import { resetQuotes } from "store/features/quotesSlice";
import { resetStepper } from "store/features/stepperSlice";
import { setSurveyPageNumber } from "store/features/surveyJsSlice";
import { customStylesSelector } from "store/selectors/selectors";
import { RootState } from "store/store";
import { useT } from "translation";
import { LSKeys, getFromLocalStorage } from "utils/ls.utils";
import CustomButton from "../CustomButton/CustomButton";
import LanguagePopover from "./LanguagePopover";
import UserPopover from "./UserPopover/UserPopover";
const NOTIFICATION_PAGE = "/home/notifications";
const HeaderRightSection = () => {
  const { t } = useT();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const customStyles = useSelector(customStylesSelector);
  const [isCustomerDetailsModalOpen, setIsCustomerDetailsModalOpen] =
    useState(false);
  const [isNoDistAvailableDialogOpen, setNoDistAvailableDialog] = useState(false)
  const initialCurrentStep = useSelector(initialCurrentStepSelector);
  const invitedAccounts = useSelector(
    (state: RootState) => state.auth.accounts.invitedAccounts
  );
  const membershipRequests = useSelector((state: RootState) => state.auth.accounts.membershipRequest);
  const allAccounts = useSelector(
    (state: RootState) => state.auth.accounts.allAccounts
  );
  const selectedAccount = useSelector(
    (state: RootState) => state.auth.accounts.selectedAccount
  );
  const availableDistAccess = useSelector((state: RootState) => state.appointment.availableDistributionAccess)
  const location = useLocation();
  const openNoDistAvailableDialog = () => setNoDistAvailableDialog(true)
  const closeNoDistAvailableDialog = () => setNoDistAvailableDialog(false)
  const onNewQuoteClick = () => {
    if (PORTAL_NAME === CORX && availableDistAccess.length === 0) {
      openNoDistAvailableDialog();
      return;
    }
    if (PORTAL_NAME === FAIRFAX) {
      setIsCustomerDetailsModalOpen(true);
    } else {
      dispatch(resetStepper(initialCurrentStep));
      dispatch(resetQuotes());
      dispatch(updateStartPageSelection([]));
      dispatch(setSurveyPageNumber(0));
      navigate("/newQuote/start");
    }
  };
  const isUserAuthenticated = !!getFromLocalStorage(LSKeys.ACCESS_TOKEN) && selectedAccount;
  console.log(allAccounts);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {/* With V2, New quote button is enabled for all user roles */}
        {isUserAuthenticated && <CustomButton
          data_cy="new-quote-button"
          onClickCB={onNewQuoteClick}
          btnText={t("NEW_QUOTE")}
        />}
        {allAccounts.length > 0 && (
          <Link to={NOTIFICATION_PAGE}>
            <Badge badgeContent={invitedAccounts.length + membershipRequests.length} color="primary">
              <AnimatedButton
                variant={
                  location.pathname === NOTIFICATION_PAGE
                    ? "contained"
                    : "outlined"
                }
                sx={{
                  position: "relative",
                  ml: "20px",
                  padding: "8px 0px",
                  borderRadius: "12px",
                  border: `1px solid ${customStyles?.muiTheme?.palette?.primary?.main}`,
                }}
              >
                <NotificationsActiveOutlinedIcon />
              </AnimatedButton>
            </Badge>
          </Link>
        )}
        <LanguagePopover />
        <Box ml={"20px"}>
          <UserPopover />
        </Box>
      </Box>
      <CustomerDetailsPopup
        isOpen={isCustomerDetailsModalOpen}
        setIsOpen={setIsCustomerDetailsModalOpen}
      />
      <Dialog
        open={isNoDistAvailableDialogOpen}
        onClose={closeNoDistAvailableDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          {t("NO_APPOINTMENT_AVAILABLE")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("NO_ASSIGNED_APPOINTMENT")}
          </DialogContentText>
          <Box sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            marginTop: "8px",
            gap: "12px"
          }}>
            <AnimatedButton onClick={closeNoDistAvailableDialog} variant="outlined">{t("CLOSE")}</AnimatedButton>
            <Link to='/home/appointments'>
              <AnimatedButton variant="contained">
                {t("REQUEST_APPOINTMENT")}
              </AnimatedButton>
            </Link>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default HeaderRightSection;
