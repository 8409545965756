import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Box, CircularProgress, Typography } from "@mui/material";
import { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { fetchSubmissionAPI } from "api/siaApplication";
import QuoteStatus from "components/Home/Quotes/QuoteStatus";
import { generateQuoteData } from "components/Home/Quotes/utils";
import Quote from "components/Quote/sections/quote/Quote";
import AnimatedButton from "components/custom/AnimatedButton";
import { animateRouteProps } from "constants/common";
import { updateStartPageSelection } from "store/features/clientSlice";
import {
  setAvailableQuotes,
  setInvalidQuotes,
  setSentQuotesToken,
} from "store/features/quotesSlice";
import {
  clientSelector,
  customStylesSelector,
} from "store/selectors/selectors";

interface SubmissionProps {}

const Submission: FunctionComponent<SubmissionProps> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { submissionId } = useParams();
  const { availableProducts } = useSelector(clientSelector);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const customStyles = useSelector(customStylesSelector);
  const [onQuotesSentCalled, setOnQuotesSentCalled] = useState(false);

  const [submissionData, setSubmissionData] = useState<Submission>();

  const [quoteData, setQuoteData] = useState<QuotesData>();

  const onQuotesSent = () => {
    fetchSubmission();
    setOnQuotesSentCalled(true);
  };

  const fetchSubmission = async () => {
    try {
      setLoading(true);
      const response = await fetchSubmissionAPI(submissionId);
      if (response.data) {
        setSubmissionData(response.data);
        const qd = generateQuoteData(response.data);
        setQuoteData(qd);
        setError(null);
        const filteredQuotes = (response.data?.quotes || []).filter((q) =>
          qd?.status === "payment_made"
            ? q?.payments?.length
            : q.status === "generated" || q.status === "shortlisted" || q.status === "bound"
        );
        const invalidQuotes = (response.data?.quotes || []).filter(
          (q) => q.status === "invalid"
        );
        dispatch(setAvailableQuotes(filteredQuotes));
        dispatch(setInvalidQuotes(invalidQuotes));
        if (filteredQuotes.some((q) => q.status === "shortlisted")) {
          dispatch(setSentQuotesToken("Quotes Sent"));
        }
        const selection = availableProducts?.filter((p) =>
          (response.data?.application_params?.products ?? []).includes(
            `${p?.product?.product_identifier}-${p?.carrier[0]}`
          )
        );
        dispatch(updateStartPageSelection(selection));
      } else {
        setError("Error fetching data.");
      }
    } catch (error) {
      setError(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (submissionId && availableProducts.length) {
      fetchSubmission();
    }
    return () => {
      dispatch(setSentQuotesToken(null));
    };
  }, [submissionId, availableProducts]);

  if (loading) {
    return (
      <Box sx={{ display: "flex", alignItems: "center", mt: "10%" }} {...animateRouteProps}>
        <CircularProgress sx={{ m: "auto" }} />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mt: "10%",
        }}
        {...animateRouteProps}
      >
        <Typography
          sx={{
            color: customStyles.muiTheme.palette.text.primary,
            fontSize: "24px",
            textAlign: "center",
            fontWeight: 600,
            mb: "16px",
          }}
        >
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        background: customStyles?.muiTheme?.palette?.background?.default,
        borderRadius: "12px",
        // p: "20px",
        // m: "-40px",
      }}
      {...animateRouteProps}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: "20px",
        }}
      >
        <AnimatedButton onClick={() => navigate(-1)}>
          <ArrowBackIosIcon fontSize="small" />
          <Typography
            sx={{
              color: customStyles?.muiTheme?.palette?.text?.primary,
              fontSize: "12px",
              mr: "2px",
              textTransform: "none",
            }}
          >
            Back
          </Typography>
        </AnimatedButton>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <Typography
            sx={{
              color: customStyles?.muiTheme?.palette?.text?.primary,
              fontSize: "12px",
              mr: "2px",
            }}
          >
            Application ID:
          </Typography>
          <Typography
            sx={{
              color: customStyles?.muiTheme?.palette?.text?.secondary,
              fontSize: "12px",
            }}
          >
            {submissionData?.application_id?.slice(0, 12)}...
          </Typography>
          <QuoteStatus quoteData={quoteData} sx={{ ml: "10px" }} />
        </Box>
      </Box>
      <Box sx={{ mx: "-20px" }}>
        <Quote
          submission={submissionData}
          isFromDashboard={true}
          status={quoteData?.status}
          onQuotesSent={onQuotesSentCalled ? null : onQuotesSent}
        />
      </Box>
    </Box>
  );
};

export default Submission;
