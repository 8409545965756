import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { RootState } from "store/store";
import NewStepLabel from "./NewStepLabel";

const StepConnector = () => {
  return (
    <Box
      sx={{
        height: "20px",
        width: "1px",
        borderRadius: "2px",
        ml: "20px",
        my: "10px",
        background: "linear-gradient(to bottom, transparent, white)",
      }}
    ></Box>
  );
};

const CustomStepper = () => {
  const location = useLocation();
  const isApplicationStep = location?.pathname?.split("/")[2] === "application";
  const { surveyModelFuncs } = useSelector(
    (state: RootState) => state.surveyJs
  );
  const {
    subSteps,
    maxSubSteps,
    maxSteps,
    currentStepLabel,
    currentSubStep,
    steps,
    currentStep,
  } = useSelector((state: RootState) => state.stepper);
  const { productSelection } = useSelector((state: RootState) => state.client);

  const dispatch = useDispatch();

  return (
    <Box>
      {steps.map((step, index) => {
        if (productSelection?.length && step.label === "Start") return null;

        return (
          <Box key={step.label} data-cy={`parentStepContainer_${step.label}`}>
            <NewStepLabel
              currentStep={
                productSelection?.length ? currentStep - 1 : currentStep
              }
              stepNum={productSelection?.length ? index - 1 : index}
              stepLabel={step.label}
              data_cy={`step-${index}`}
            />
            {maxSubSteps !== 0 &&
            currentStepLabel === step.label &&
            isApplicationStep ? (
              <StepConnector />
            ) : null}
            {maxSubSteps !== 0 &&
              currentStepLabel === step.label &&
              isApplicationStep &&
              subSteps?.map((s, ind) => {
                return (
                  <Box key={s.label}>
                    <NewStepLabel
                      outerDivStyles={{
                        height: "20px",
                        width: "20px",
                        ml: "10px",
                      }}
                      labelStyles={{ fontSize: "12px" }}
                      stepLabel={s.label}
                      stepNum={ind}
                      currentStep={currentSubStep}
                      isSubStep={true}
                      data_cy='subStep-container'
                    />
                    {ind !== (maxSubSteps ?? 0) - 1 ? <StepConnector /> : null}
                  </Box>
                );
              })}
            {index !== (steps?.length ?? 0) - 1 ? <StepConnector /> : null}
          </Box>
        );
      })}
    </Box>
  );
};

export default CustomStepper;
