// import { CircularProgress } from "@mui/material";
import { Chip, CircularProgress, TableSortLabel, debounce } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useMutation } from "@tanstack/react-query";
import { ToastCtx } from "components/Toast/ToastProvider";
import NoRecordsFound from "modules/Agents/NoRecordsFound";
import { fetchAppointmentsList } from "modules/Appointments/api";
import { AppointmentFilterCtx } from "modules/Appointments/AppointmentFiltersProvider";
import { IAppointments, IFetchAppointmentsList } from "modules/Appointments/types";
import ValidityChip from "modules/Appointments/ValidityChip";
import moment from "moment";
import * as React from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { useT } from "translation";
import { IListResponse } from "types";

interface Data {
  id: string;
  brokerName: string;
  insurer: string;
  product: string;
  validity: string;
  status: number;
  created_at: number;
}

interface HeadCell {
  id: keyof Data;
  label: string;
  rightAlign: boolean;
  isSortable: boolean;
}

function EnhancedTableHead() {
  const { t } = useT()
  const { appointmentFilters, setAppointmentFilters } = React.useContext(AppointmentFilterCtx);
  const headCells: readonly HeadCell[] = [
    {
      id: "brokerName",
      rightAlign: false,
      label: t('BROKERY_NAME'),
      isSortable: false,
    },
    {
      id: "insurer",
      rightAlign: false,
      label: t('POLICIES_PAGE.tableHeaders.insurer'),
      isSortable: false,
    },
    {
      id: "product",
      rightAlign: false,
      label: t("PRODUCT"),
      isSortable: false,
    },
    {
      id: "validity",
      rightAlign: true,
      label: t("VALIDITY"),
      isSortable: false,
    },
    {
      id: "status",
      rightAlign: true,
      label: t("STATUS"),
      isSortable: false,
    },
    {
      id: "created_at",
      rightAlign: true,
      label: t("CREATED_AT"),
      isSortable: true,
    },
  ];
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} padding={"normal"}>
            {headCell.isSortable ? <TableSortLabel
              active={appointmentFilters.orderBy === headCell.id}
              direction={appointmentFilters.orderBy === headCell.id ? appointmentFilters.order : 'desc'}
              onClick={() => {
                setAppointmentFilters({ ...appointmentFilters, orderBy: headCell.id, order: appointmentFilters.order === 'asc' ? 'desc' : 'asc' });
              }}
            >
              {headCell.label}
            </TableSortLabel> : headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function AppointmentsTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const { t } = useT();
  const { setToast } = React.useContext(ToastCtx);
  const accountId = useSelector(
    (state: RootState) => state.auth.accounts?.selectedAccount?.id
  );
  const { appointmentFilters } = React.useContext(AppointmentFilterCtx);
  const {
    mutate: fetchAppointments,
    data,
    isPending,
  } = useMutation<IListResponse<IAppointments>, any, IFetchAppointmentsList>({
    mutationFn: (data: IFetchAppointmentsList) => fetchAppointmentsList.mutationFn(data, accountId),
    onError: ({ error }) => {
      if (error.code === "insufficient_permissions") {
        return setToast({
          severity: "error",
          text: t("NO_SUFFICIENT_PERMISSION"),
        });
      }
      setToast({
        severity: "error",
        text: t("SOMETHING_WENT_WRONG"),
      });
    },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchAppointmentsList = React.useCallback(
    debounce(fetchAppointments, 300),
    [fetchAppointments]
  );

  React.useEffect(() => {
    const statuses = (appointmentFilters?.status || []) as string[];
    const products = (appointmentFilters?.product || []) as string[];
    const insurers = (appointmentFilters?.insurer || []) as string[];
    const requestPayload: IFetchAppointmentsList = {
      expand: ["intermediary", "insurer_product"],
      limit_by: {
        page: page + 1,
        per_page: rowsPerPage,
      },
      ...(appointmentFilters.order && appointmentFilters.orderBy ? {
        sort_by: {
          attribute: appointmentFilters.orderBy,
          order: appointmentFilters.order
        }
      } : {}),
      ...(appointmentFilters.intermediary_name ? { intermediary_name: appointmentFilters.intermediary_name } : {}),
      ...(statuses && statuses.length > 0 ? { statuses } : {}),
      ...(products && products.length > 0 ? { insurance_products: products } : {}),
      ...(insurers && insurers.length > 0 ? { insurer_name: insurers } : {}),
      ...(appointmentFilters.start_date && appointmentFilters.end_date ? {
        created_at: {
          from_date: moment(appointmentFilters.start_date).toISOString(),
          end_date: moment(appointmentFilters.end_date).toISOString()
        }
      } : {})
    };

    debouncedFetchAppointmentsList(requestPayload);
  }, [appointmentFilters, page, rowsPerPage, debouncedFetchAppointmentsList]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const rows = React.useMemo(() => data?.data || [], [data?.data]);
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const statusColorMap = {
    pending: <Chip sx={{ p: 0 }} label={t("PENDING")} variant="filled" color="primary" />,
    rejected: <Chip sx={{ p: 0 }} label={t("REJECTED")} variant="filled" color="error" />,
    cancelled: <Chip sx={{ p: 0 }} label={t("CANCELLED")} variant="filled" color="error" />,
    expired: <Chip sx={{ p: 0 }} label={t("EXPIRED")} variant="filled" color="error" />,
    approved: <Chip sx={{ p: 0 }} label={t("APPROVED")} variant="filled" color="success" />,
  };


  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <EnhancedTableHead />
            <TableBody>
              {rows.map((row, index) => {
                const labelId = `enhanced-table-${index}`;

                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={row.id}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell component="th" id={labelId} scope="row" sx={{ textTransform: "capitalize" }}>
                      {row?.intermediary?.name || '--'}
                    </TableCell>
                    <TableCell component="th" id={labelId} scope="row" sx={{ textTransform: "capitalize" }}>
                      {row?.insurer_product.insurer?.name || '--'}
                    </TableCell>
                    <TableCell component="th" id={labelId} scope="row">
                      {row.insurer_product?.insurance_product || '--'}
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: 'center', gap: '8px' }}>
                        <Box sx={{ whiteSpace: 'nowrap' }}>
                          {row.starts_at && row.expires_at
                            ? `${moment(row.starts_at).format('MMM DD, YYYY')} - ${moment(row.expires_at).format('MMM DD, YYYY')}`
                            : '--'}
                        </Box>
                        {row.starts_at && row.expires_at && (
                          <ValidityChip
                            startDate={moment(row.starts_at)}
                            endDate={moment(row.expires_at)}
                            status={row.status}
                          />
                        )}
                      </Box>
                    </TableCell>
                    <TableCell>{statusColorMap[row.status]}</TableCell>
                    <TableCell>{moment(row.created_at).format('DD-MM-YYYY')}</TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {rows.length === 0 && !isPending && <NoRecordsFound />}
        {isPending && (
          <Box display={"flex"} justifyContent={"center"} p={4}>
            <CircularProgress />
          </Box>
        )}
        <TablePagination
          sx={{ color: "black" }}
          labelRowsPerPage={t("TABLES.rowsPerPage")}
          labelDisplayedRows={({ from, to, count }) => {
            return `${from}–${to} ${t("TABLES.of")} ${
              count !== -1 ? count : `${t("TABLES.moreThan")} ${to}`
            }`;
          }}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
