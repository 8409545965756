import { Box } from "@mui/material";

import OutletHeader from "components/Home/Common/OutletHeader";
import { animateRouteProps } from "constants/common";
import SettingsPanel from "modules/Profile/SettingsPanel";
import { useT } from "translation";

const UserProfileSettings = () => {
  const { t } = useT();
  return (
    <Box {...animateRouteProps}>
      <Box
        sx={{
          backgroundColor: "white",
          borderRadius: "12px",
          p: "20px",
        }}
      >
        <OutletHeader title={t("SETTINGS")} />
        <Box>
          <SettingsPanel />
        </Box>
      </Box>
    </Box>
  );
};

export default UserProfileSettings;
