import axios from "axios";
import { DOMAIN_NAME } from "config";
import { errorStatusCodes } from "constants/common";
import { stepperSteps } from "constants/stepperSteps";
import { setErrorAndShowExitBtn } from "store/features/clientSlice";

import { RootState, store } from "store/store";
import { LSKeys, getFromLocalStorage } from "utils/ls.utils";
import { API_VERSION, sendMessageToClient } from "utils/utils";

export const callApi = async <Response>(
  apiConfig: CallAPIConfig,
  includeVersionInBaseURL: boolean = true,
  state?: RootState
): Promise<Response> => {
  let reduxState = { ...state };
  if (!state) {
    const reduxStore = await import("store/store"); //dynamic import to avoid cyclic dependency
    reduxState = reduxStore.store.getState();
  }
  const initialConfigObject: Partial<CallAPIConfig> = {
    method: "GET",
    authenticated: true,
    params: {},
    body: {},
    headers: {},
    clientId: reduxState?.client?.clientAPIKey,
  };

  const { endpoint, method, headers, params, body, clientId } = {
    ...initialConfigObject,
    ...apiConfig,
  };
  const accessToken = getFromLocalStorage(LSKeys.ACCESS_TOKEN);
  const mandatoryHeaders = {
    "Content-Type": "application/json",
    "X-API-KEY": clientId,
    ...(accessToken
      ? { "X-STERE-USER-TOKEN": accessToken, "X-STERE-DOMAIN": DOMAIN_NAME }
      : {}),
    // Authorization: process.env.REACT_APP_SIA_AUTH_TOKEN,
  };

  const requestHeaders = {
    ...mandatoryHeaders,
    ...headers,
  };

  const config = {
    method,
    params,
    headers: requestHeaders,
    data: body,
    url: `${
      process.env.REACT_APP_SERVER_URL +
      (includeVersionInBaseURL ? `/${API_VERSION}` : "") +
      endpoint
    }`,
  };

  return axios
    .request(config)
    .then((response) => response.data)
    .catch((error) => {
      if (axios.isCancel(error)) {
        throw { ...error, isCanceled: true };
      } else if (
        error.response &&
        errorStatusCodes.includes(error.response.status)
      ) {
        store?.dispatch(setErrorAndShowExitBtn(true));
        sendMessageToClient(
          {
            type: "FAILURE",
            details: {
              section:
              store?.getState()?.stepper?.currentStepLabel,
            },
          },
          store?.getState()?.client?.clientUrl
        );
      }
      throw error;
    });
};
