import { getClientFeaturesAPI } from "api/client";
import { fetchAvailableProductsAPI } from "api/siaApplication";
import { COUNTRY, IS_HOSTED_INSURANCE, PORTAL_NAME } from "config";
import { ClientProduct } from "customTypes/ClientProducts";
import flagsmith from "flagsmith";
import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  fetchAvailableProducts,
  fetchClientFeatures,
  setAvailableProducts,
  setClientFeatures,
  setLoadingValue,
  updateStartPageSelection,
} from "store/features/clientSlice";
import { RootState } from "store/store";

export function* fetchProducts() {
  try {
    const { clientAPIKey, productSelection } = yield select(
      (state: RootState) => state.client
    );
    let response = yield call(fetchAvailableProductsAPI, clientAPIKey);
    if (response?.status === 200 && response?.data?.success) {
      yield put(setAvailableProducts(response.data.results));
      if (productSelection?.length) {
        yield put(
          updateStartPageSelection(
            response.data.results?.filter((prod: ClientProduct) =>
              productSelection.includes(prod?.product?.product_identifier)
            )
          )
        );
      }
      // Feature flag is set up for payments with auto renewals. Below helps us identify autopylot users.
      if (IS_HOSTED_INSURANCE) {
        const productIdentifier = response?.data?.results?.[0]?.product?.product_identifier;
        const traits = { portal_name: PORTAL_NAME };
        traits["country"] = COUNTRY;
        traits["product"] = productIdentifier;
        flagsmith.identify(PORTAL_NAME, traits);
      }
    } else {
      yield put(setLoadingValue(false));
    }
  } catch (e) {
    yield put(setLoadingValue(false));
    console.error(e);
  }
}

export function* fetchClient() {
  try {
    const { clientAPIKey } = yield select((state: RootState) => state.client);
    let response = yield call(getClientFeaturesAPI, clientAPIKey);
    if (response?.status === 200 && response?.data?.success) {
      yield put(setClientFeatures(response.data.results));
    }
  } catch (e) {
    console.error(e);
    yield put(setClientFeatures(null));
  }
}

export default function* clientSaga() {
  yield takeLatest(fetchAvailableProducts.type, fetchProducts);
  yield takeLatest(fetchClientFeatures.type, fetchClient);
}
