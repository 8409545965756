import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

import { useMediaQuery } from "@mui/material";
import { StepperStep, stepperSteps } from "constants/stepperSteps";
import { updateStartPageSelection } from "store/features/clientSlice";
import { setSteps } from "store/features/stepperSlice";
import { RootState } from "store/store";
import { useT } from "translation";
import { getProductTitle } from "utils/utils";
import { ProductIconMapping } from "../utils";
import ProductSelectionCard from "./ProductSelectionCard";

interface TabPanelProps {
  children?: React.ReactNode;
  value: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={!value}
      id={`simple-tabpanel-${value}`}
      aria-labelledby={`simple-tab-${value}`}
      {...other}
    >
      <Box sx={{ p: 2 }}>{children}</Box>
    </Box>
  );
}

export default function StartSectionTabPanel() {
  const isMobile = useMediaQuery("max-width:425px");
  const [value, setValue] = React.useState<ProductCategory>("BUSINESS");
  const dispatch = useDispatch();

  const [availableTabs, setAvailableTabs] = React.useState([]);

  const { t } = useT();
  const { availableProducts, selectedProducts } = useSelector(
    (state: RootState) => {
      return state.client;
    }
  );

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: ProductCategory
  ) => {
    dispatch(updateStartPageSelection([]));
    setValue(newValue);
  };
  React.useEffect(() => {
    const tabSet = new Set<string>();
    availableProducts.forEach((p) => {
      tabSet.add(p?.product?.product_category);
    });
    const arr = Array.from(tabSet);
    setAvailableTabs(arr);
    setValue(arr[0] as ProductCategory);
  }, [availableProducts]);

  React.useEffect(() => {
    if (selectedProducts.includes("HEALTH-HBA")) {
      dispatch(
        setSteps(
          stepperSteps([
            StepperStep.Start,
            StepperStep.Application,
            StepperStep.Confirmation,
          ])
        )
      );
    } else {
      dispatch(setSteps(stepperSteps()));
    }
  }, [selectedProducts]);

  return (
    <Box sx={{ width: "100%" }}>
      <Box>
        <Tabs value={value} onChange={handleChange}>
          {availableTabs.map((tab) => (
            <Tab
              data-cy={`tab-${tab}`}
              key={tab}
              sx={{
                textTransform: "none",
              }}
              value={tab}
              label={t(`START_PAGE_TABS.${tab}`)}
            />
          ))}
        </Tabs>
      </Box>
      <TabPanel value={value}>
        <Box
          sx={{
            display: "flex",
            gap: isMobile ? "10px" : "20px",
            flexWrap: "wrap",
          }}
        >
          {availableProducts
            ?.filter((product) => product?.product?.product_category === value)
            ?.sort((product) => (product?.product?.ready_to_use ? -1 : 1))
            .map((p) => {
              return (
                <ProductSelectionCard
                  key={p.product.id}
                  id={p.product.id}
                  product={p}
                  prodIcon={ProductIconMapping(
                    p.product.product_identifier,
                    !p?.product?.ready_to_use
                  )}
                  isComingSoon={!p?.product?.ready_to_use}
                  prodTitle={getProductTitle(p)}
                />
              );
            })}
        </Box>
      </TabPanel>
    </Box>
  );
}
