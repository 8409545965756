import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box, List, ListItemButton, ListItemText } from "@mui/material";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useSelector } from "react-redux";

import AnimatedButton from "components/custom/AnimatedButton";
import { locales } from "constants/common";
import { customStylesSelector } from "store/selectors/selectors";
import { Languages, useSetTranslationLang, useT } from "translation";

const LanguagePopover = () => {
  const { lang } = useT();
  const setTranslationLang = useSetTranslationLang();
  const customStyles = useSelector(customStylesSelector);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [showLangs, setShowLangs] = useState<boolean>(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setShowLangs(!showLangs);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setShowLangs(!showLangs);
    setAnchorEl(null);
  };

  const handleTranslationClick = (lCode: Languages) => {
    setTranslationLang(lCode);
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? "language-popover" : undefined;

  return (
    <Box>
      <AnimatedButton
        aria-describedby={id}
        onClick={handleClick}
        variant="outlined"
        sx={{
          position: "relative",
          color: customStyles?.muiTheme?.palette?.primary?.main,
          py: "8px",
          ml: "20px",
          backgroundColor: "white",
          borderRadius: "12px",
          border: `1px solid ${customStyles?.muiTheme?.palette?.primary?.main}`,
        }}
        data_cy="language-popover"
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography sx={{ fontWeight: 500 }}>
            {lang.charAt(0).toUpperCase() + lang.slice(1)}
          </Typography>
          {showLangs ? (
            <KeyboardArrowUpIcon
              style={{
                width: "0.8em",
                marginLeft: "5px",
                color: customStyles?.muiTheme?.palette?.primary?.main,
              }}
            />
          ) : (
            <KeyboardArrowDownIcon
              style={{
                width: "0.8em",
                marginLeft: "5px",
                color: customStyles?.muiTheme?.palette?.primary?.main,
              }}
            />
          )}
        </Box>
      </AnimatedButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{
          mt: "10px",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <List sx={{ p: "0px", minWidth: "150px" }}>
          {locales.map((l) => (
            <ListItemButton
              key={l.code}
              id={l.code}
              selected={lang === l.code}
              sx={{
                backgroundColor:
                  lang === l.code
                    ? `${customStyles?.button?.backgroundColor} !important`
                    : "white",
              }}
              onClick={() => {
                if (lang !== l.code)
                  handleTranslationClick(l.code as Languages);
              }}
              data-cy={`lang-${l.code}`}
            >
              <ListItemText
                primary={
                  <Typography
                    sx={{
                      color:
                        lang === l.code
                          ? "white"
                          : customStyles?.button?.backgroundColor,
                      textTransform: "none",
                    }}
                  >
                    {l.label}
                  </Typography>
                }
              />
            </ListItemButton>
          ))}
        </List>
      </Popover>
    </Box>
  );
};

export default LanguagePopover;
